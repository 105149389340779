import classNames from 'classnames/bind';
import React from 'react';

import { DateFormat } from '@components/DateFormat/DateFormat';
import { Link } from '@components/Link/Link';
import { getReadingTime } from '@components/Pages/Blog/BlogPost/BlogPost';
import { Paragraph } from '@components/Paragraph/Paragraph';
import type { IBlogPost } from '@prismic';
import { routes } from '@routes';

import styles from './BlogListItem.module.scss';
import ArrowRightIcon from '@icons/arrowRight.svg';

interface IProps {
  theme?: 'default' | 'featured';
  id: string;
  uid: string | null;
  data: IBlogPost['data'];
  title: string;
  preview: string;
}

const cx = classNames.bind(styles);

export const BlogListItem: React.FC<IProps> = ({
  theme = 'default',
  id,
  uid,
  data,
  title,
  preview,
}) => (
  <Link
    key={id}
    className={cx('post', theme)}
    href={`${routes.blog}/${uid}`}
    testId="blog-detail-link"
  >
    {data.cover_image?.url && (
      <figure className={styles.previewImageWrapper}>
        <img
          alt={data.cover_image.alt ?? ''}
          className={styles.previewImage}
          height={theme === 'featured' ? 296 : 158}
          src={data.cover_image.url}
          width={theme === 'featured' ? 565 : 300}
        />
      </figure>
    )}
    <span className={styles.content}>
      <span className={styles.date}>
        <DateFormat date={data.date} /> · <span>{getReadingTime(data.body)}</span>
      </span>
      <strong className={styles.title}>{title}</strong>
      <Paragraph className={styles.preview} color="default">
        {preview}
      </Paragraph>
      {theme === 'featured' && (
        <span className={styles.buttonWrapper}>
          <span className={styles.readArticleFakeButton}>
            Read article{' '}
            <span className={styles.linkArrowWrapper}>
              <ArrowRightIcon />
            </span>
          </span>
        </span>
      )}
    </span>
  </Link>
);
