import type { NextPage } from 'next';
import React from 'react';

import { BlogList } from '@components/Pages/Blog/BlogList/BlogList';
import type { IBlogPost } from '@prismic';
import { getBlogPosts } from '@prismic';

interface IProps {
  preview: boolean;
  posts: IBlogPost[];
}

const Page: NextPage<IProps> = ({ posts }) => <BlogList posts={posts} />;

export const getStaticProps = async () => {
  const blogPosts = await getBlogPosts();

  return {
    props: {
      posts: blogPosts ?? [],
    },
  };
};

export default Page;
