import { asText } from '@prismicio/helpers';
/* eslint-disable camelcase */
import React from 'react';

import { Col } from '@components/Layout/Col/Col';
import { Head } from '@components/Layout/Head';
import { Row } from '@components/Layout/Row/Row';
import { BlogListItem } from '@components/Pages/Blog/BlogListItem/BlogListItem';
import { Section } from '@components/Section/Section';
import { Text } from '@components/Text/Text';
import type { IBlogPost } from '@prismic';
import { routes } from '@routes';

import styles from './BlogList.module.scss';

interface IProps {
  posts: IBlogPost[];
}

export const BlogList: React.FC<IProps> = ({ posts }) => {
  const [firstArticle, ...otherArticles] = posts;

  return (
    <>
      <Head canonical={routes.blog} description="Content Delivery Network" title="Blog" />
      <Section padding={{ top: 170, bottom: 120 }} tag="header" theme="tiles">
        <Text
          align="center"
          className="mb-8"
          color="gray900"
          display="block"
          size={48}
          tag="h1"
          weight="bold"
        >
          CDN77 blog<span className={styles.dot}>.</span>
        </Text>
        {firstArticle && (
          <BlogListItem
            data={firstArticle.data}
            id={firstArticle.id}
            preview={asText(firstArticle.data.preview)}
            theme="featured"
            title={asText(firstArticle.data.title)}
            uid={firstArticle.uid}
          />
        )}
      </Section>
      <Section padding={{ top: 120, bottom: 150 }}>
        <div className={styles.articlesWrapper}>
          <Row gapY="xl" horizontalAlign="between">
            {otherArticles.map(post => (
              <Col key={post.id} gapY="xl" md="auto">
                <BlogListItem
                  data={post.data}
                  id={post.id}
                  preview={asText(post.data.preview)}
                  title={asText(post.data.title)}
                  uid={post.uid}
                />
              </Col>
            ))}
          </Row>
        </div>
      </Section>
    </>
  );
};
